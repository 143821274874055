import { themes } from '../base'

export const isClient = typeof window !== 'undefined'

export const isMobile =
  isClient && themes.light.breakpoint.mobile > window.innerWidth

export const wemN = pxSize => {
  const baseScreenSize = 1920
  return (pxSize / baseScreenSize) * 100
}

export const wem = pxSize => wemN(pxSize) + 'vw'

export const getParameterByName = (name, url) => {
  if (!url) url = isClient ? window.location.href : ''
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const isConstructionMode = true

export const correctPW = '122019'

export const sleep = sec =>
  new Promise(resolve => setTimeout(resolve, sec * 1000))

export const localStorageSevice = (key, initialValue) => {
  let storedValue = null

  try {
    // Get from local storage by key
    const item = isClient && window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    storedValue = item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    console.log(error)
    storedValue = initialValue
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save to local storage
      isClient && window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}

export const Guard = () => {
  const [storedValue, setValue] = localStorageSevice('pw', '')
  const guard = isConstructionMode && storedValue !== correctPW
  if (guard && isClient) {
    // const pw = window.prompt('Please enter the Password', '')
    // if (pw) {
    //   setValue(pw)
    //   if (pw === correctPW) window.location.reload()
    // }
    setTimeout(() => {
      setValue(correctPW)
    }, 100)
  }
  return guard
}

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, c - c / 10)
  }
}
