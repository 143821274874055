const font = {
  en: {
    type1: 'CircularPro, system-ui',
    type2: "'CircularPro', 'Noto Sans KR'",
  },
  ko: {
    type1: 'Noto Sans KR',
    type2: "'CircularPro', 'Noto Sans KR'",
  },
}
const transitions = {
  short: {
    duration: 0.28,
  },
  long: {
    duration: 0.36,
  },
  test: {
    duration: 3,
  },
}

export const themes = {
  light: {
    // Add colors directly, cause shared color from framer canvas make gatsby build error
    key: 'light',
    color: {
      primaryText: '#222222',
      secondaryText: '#5f6368',
      buttonText: '#FFFFFF',
      framerBlue: '#3479FF',
      framerPurple: '#8302FF',
      background: '#FFFFFF',
      contentBg: '#F8F9FA',
      unselectedBg: '#dbdce0',
      boxShadow: '0px 2px 16px 0px rgba(161, 161, 180, 0.15)',
      boxShadowHover: '0px 12px 36px 0px rgba(161,161,180,0.3)',
      border: '1px solid #EEEEEE',
      divider: '#dbdce0',

      cardBg: '#FFFFFF',
      gradientA: 'linear-gradient(180deg, #b201fe 0%, rgba(150,45,255,1) 100%)',
      gradientB: 'linear-gradient(180deg, #6222e2 0%, rgba(134,7,255,1) 100%)',
      gradientC: 'linear-gradient(180deg, #8400ff 0%, rgba(95,0,255,1) 100%)',

      labelPrimary: '#222222',
      primaryColor: 'rgb(254, 44, 85)',
      labelSecondary: 'rgba(60, 60, 67, 0.6)',
      shelfHeader: 'rgb(0, 0, 0)',
      topBackground: 'rgb(248, 249, 250)',
      primaryBg: '#FFFFFF',
      headerShadow: 'inset 0px -1px 0px 0px #eeeeee',
      headerText: '#333333',
      themeTogglePin: '#ffffff',
      themeToggleBg: '#5F6368',
      badgeColor: '#663ef5',
      segmentControlBg: '#EFEFF0',
      segmentControlBlock: '#ffffff',
    },
    font,
    breakpoint: {
      tablet: 1650,
      mobile: 1200,
    },
    transitions,
  },
  dark: {
    // Add colors directly, cause shared color from framer canvas make gatsby build error
    key: 'dark',
    color: {
      primaryText: '#FFFFFF',
      secondaryText: '#FFFFFF',
      buttonText: '#FFFFFF',
      framerBlue: '#3479FF',
      framerPurple: '#E876FD',
      background: '#0A0A0A',
      contentBg: '#151515',
      unselectedBg: '#444444',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      boxShadowHover: '0px 16px 42px 0px rgba(102,62,245,0.4)',
      border: '0px solid #EEEEEE',
      divider: '#333333',

      cardBg: 'linear-gradient(180deg, #6222e2 0%, rgba(134,7,255,1) 100%)',
      gradientA: 'linear-gradient(180deg, #b201fe 0%, rgba(150,45,255,1) 100%)',
      gradientB: 'linear-gradient(180deg, #6222e2 0%, rgba(134,7,255,1) 100%)',
      gradientC: 'linear-gradient(180deg, #8400ff 0%, rgba(95,0,255,1) 100%)',

      labelPrimary: '#ffffff',
      primaryColor: 'yellow',
      labelSecondary: 'blue',
      shelfHeader: 'green',
      topBackground: 'rgb(21, 21, 21)',
      primaryBg: '#0a0a0a',
      headerShadow: 'inset 0px -1px 0px 0px #222222',
      headerText: '#dbdce0',
      themeTogglePin: '#000000',
      themeToggleBg: '#DBDCE0',
      badgeColor: '#663ef5',
      segmentControlBg: '#1C1C1F',
      segmentControlBlock: '#636366',
    },
    font,
    breakpoint: {
      tablet: 1650,
      mobile: 1200,
    },
    transitions,
  },
}
