// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-are-you-having-fun-js": () => import("../src/pages/are-you-having-fun.js" /* webpackChunkName: "component---src-pages-are-you-having-fun-js" */),
  "component---src-pages-construction-mode-js": () => import("../src/pages/construction-mode.js" /* webpackChunkName: "component---src-pages-construction-mode-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

