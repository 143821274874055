import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import appReducer from './reducer'
import { isClient } from '../../base/utils'

const reducer = combineReducers({
  app: appReducer,
  form: formReducer, // mounted under "form"
})

const store = createStore(
  reducer,
  isClient &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
